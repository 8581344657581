<template>
    <div
        style="
            overflow-y:visible;
            overflow-x:hidden;
            width:100%;
            height:770px;
            border:3px solid #4590a0;
        "
    >
        <br>
        <!-- SEARCH PARAMETERS -->
        <v-expansion-panels focusable v-model="search_pannel">
            <v-expansion-panel style="background: #f6c20a8f">
                <v-expansion-panel-header>
                    <v-row no-gutters>
                        <v-col cols="8">
                            <h3>Search Parameters</h3>
                        </v-col>
                        <v-col cols="4">
                            <v-tooltip bottom v-if="search_results.length > 0 && search_mode === true">
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        large
                                        color="red white--text"
                                        v-on="on"
                                        @click="clearSearchResults()"
                                    >
                                        <v-icon large>cancel</v-icon> Clear Search Results
                                    </v-btn>
                                </template>
                                <span>Clear Search Results</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-row>
                                <v-col cols="12" sm="1" style="max-width:50px">
                                    <v-switch
                                        color="secondary"
                                        :disabled="false"
                                        v-model="search_fields.loan_number.search"
                                    ></v-switch>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        v-model="search_fields.loan_number.value"
                                        prefix="L"
                                        label="Loan Number"
                                        type="number"
                                        color="secondary"
                                        required
                                        :disabled="!search_fields.loan_number.search"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="1" style="max-width:50px">
                                    <v-switch
                                        color="secondary"
                                        :disabled="false"
                                        v-model="search_fields.loan_entity_id_number.search"
                                    ></v-switch>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <MemberSearch :field_disabled="!search_fields.loan_entity_id_number.search" :member="loan_entity" label="Loan Entity" />
                                </v-col>

                                <v-col cols="12" sm="1" style="max-width:50px">
                                    <v-switch
                                        color="secondary"
                                        :disabled="false"
                                        v-model="search_fields.agent.search"
                                    ></v-switch>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <AgentSearch :field_disabled="!search_fields.agent.search" :member="loan_agent" label="Loan Agent" />
                                </v-col>
                            </v-row>
                        </v-col>
                    
                        <v-col cols="12" sm="12">
                            <v-row>
                                <!-- <v-col cols="12" sm="1" style="max-width:50px">
                                    <v-switch
                                        color="secondary"
                                        :disabled="false"
                                        v-model="search_fields.effective_date.search"
                                    ></v-switch>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-menu
                                        v-model="effective_date_menu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="search_fields.effective_date.value"
                                            label="Effective Date"
                                            color="secondary"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            :disabled="!search_fields.effective_date.search"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            color="secondary"
                                            v-model="search_fields.effective_date.value"
                                            @input="effective_date_menu = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col> -->

                                <v-col cols="12" sm="1" style="max-width:50px">
                                    <v-switch
                                        color="secondary"
                                        :disabled="false"
                                        v-model="search_fields.days_since_last_contact.search"
                                    ></v-switch>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        v-model="search_fields.days_since_last_contact.value"
                                        type="number"
                                        label="Minimum days since last contact"
                                        color="secondary"
                                        :disabled="!search_fields.days_since_last_contact.search"
                                        required
                                        hint="e.g. if = 20, then we return cases/people whose last-contact >= 20 days"
                                        persistent-hint
                                    ></v-text-field>
                                </v-col>


                                <v-col cols="12" sm="1" style="max-width:50px">
                                    <v-switch
                                        color="secondary"
                                        :disabled="false"
                                        v-model="search_fields.days_till_nex_payment.search"
                                    ></v-switch>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        v-model="search_fields.days_till_nex_payment.value"
                                        type="number"
                                        label="Next Payment Within (X Days)"
                                        color="secondary"
                                        :disabled="!search_fields.days_till_nex_payment.search"
                                        required
                                        hint="e.g. if = 8, then we return people whom next-payment-days <= 8 days"
                                        persistent-hint
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Searching Cases
                        <v-progress-linear
                            indeterminate
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom v-if="search_field_selected">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    large
                                    color="success"
                                    v-on="on"
                                    @click="submitSearchQuery()"
                                >
                                    <v-icon large>search</v-icon>
                                </v-btn>
                            </template>
                            <span>Submit Search Query</span>
                        </v-tooltip>
                    </v-card-actions>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <!-- SEARCH RESULTS -->
        <v-row>
            <!-- Entity List -->
            <v-col
                v-if="search_results.length > 0"
                sm="3"
                fill-height
                transition="slide-x-transition"
            >
                <v-subheader style="background-color: #4590a0; color: yellow;"><b>People with Outstanding Loans ({{ search_results.length }})</b></v-subheader>

                <div
                    flat
                    style="
                        overflow-y:scroll;
                        overflow-x:hidden;
                        width:100%;
                        height:600px;
                        border-right:3px solid #4590a0;
                    "
                >
                    <v-list three-line max-height="500">
                        <v-list-item-group
                            v-model="selectedEntityIndex"
                            active-class="secondarylight"
                        >
                            <v-container
                                v-for="(item) in search_results"
                                :key="'item-'+item._id"
                                style="padding:0px"
                                @click="viewLoanDetails(item, selectedEntityIndex)"
                            >
                                <v-divider></v-divider>

                                <v-list-item>
                                    <template v-slot:default="{ active }">
                                        <v-list-item-avatar
                                            color="success black--text"
                                        >
                                            {{ item.entity.name.charAt(0) }}{{ item.entity.surname.charAt(0) }}
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title v-html="`<span style='color:black; font-size:14px'><b>${item.entity.name} ${item.entity.surname}</b> (${item.entity.id_number})</span>`"></v-list-item-title>
                                            <v-list-item-subtitle
                                                v-html="`
                                                    <span style='font-size:14px'>
                                                        <!-- <b style='color:#4590a0'>Entity:</b> -->
                                                        <b style='color:red'>Last Contact:</b> ${item.days_since_last_contact < 0 ? '---------' : Math.floor(item.days_since_last_contact) + ' days ago'}
                                                    </span> 

                                                    <br/>
                                                    
                                                    <span style='font-size:14px'>
                                                        <b style='color:#4590a0'>Payment in:</b> ${item.days_till_nex_payment < 0 ? '---------' : Math.floor(item.days_till_nex_payment) + ' days'}
                                                    </span>

                                                    <!--
                                                    &mdash;
                                                    
                                                    <span style='font-size:14px'>
                                                        <b style='color:#4590a0'>Tot:</b> R${getTotalOutstanding(item)}
                                                    </span>
                                                    
                                                    -->`"
                                            ></v-list-item-subtitle>
                                        </v-list-item-content>


                                        <v-list-item-action>
                                            <v-icon
                                                v-if="active"
                                                color="black"
                                            >
                                            mdi-chevron-right-circle
                                            </v-icon>
                                        </v-list-item-action>
                                    </template>
                                </v-list-item>
                            </v-container>
                        </v-list-item-group>
                    </v-list>
                </div>
            </v-col>

            <!-- Loan Details -->
            <v-col
                sm="9"
                v-if="(
                    selectedEntity !== null &&
                    selectedEntityIndex !== undefined &&
                    selectedEntityIndex !== null &&
                    search_results.length > 0
                )"
                style="margin-top:10px"
            >
                <!-- LOAN DETAILS -->
                <div>
                    <v-toolbar
                        color="secondary"
                        dark
                        class="pa-0"
                    >
                        <!-- ACTION BUTTONS -->

                        <!-- CALCULATE LOAN BALANCE -->
                        <!-- <v-tooltip bottom v-if="selectedEntity !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="white black--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openViewDebitOrderStatus()"
                                >
                                    <v-icon medium>mdi-wallet</v-icon> Check Balance
                                </v-btn>
                            </template>
                            <span>Calculate Loan Balance</span>
                        </v-tooltip> -->
                        <h3>{{selectedEntity.entity.name}} {{selectedEntity.entity.surname}} ({{ selectedEntity.entity.id_number }})</h3>

                        <v-spacer></v-spacer>
                        <v-tooltip bottom v-if="selectedEntity !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    large
                                    color="black white--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="callClient()"
                                >
                                    <v-icon large>mdi-phone</v-icon> Client
                                </v-btn>
                            </template>
                            <span>Call Client</span>
                        </v-tooltip>
                        <!-- <v-tooltip bottom v-if="selectedEntity !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    large
                                    color="orange white--text"
                                    class="mx-2"
                                    v-on="on"
                                >
                                    <v-icon large>mdi-phone</v-icon> Agent
                                </v-btn>
                            </template>
                            <span>Call Agent</span>
                        </v-tooltip> -->

                        <!-- Loan Sub-Tabs -->
                        <template v-slot:extension>
                            <v-tabs
                                v-model="loan_details_sub_tab"
                                align-with-title
                            >
                                <v-tabs-slider color="yellow"></v-tabs-slider>

                                <v-tab>
                                    <v-badge
                                        v-if="selectedEntity.upcoming_loan_schedules.length > 0"
                                        color="red"
                                        :content="selectedEntity.upcoming_loan_schedules.length"
                                    >
                                        Upcoming Loans
                                    </v-badge>
                                    <span v-else>Upcoming Loans</span>
                                </v-tab>

                                <v-tab>
                                    <v-badge
                                        v-if="selectedEntity.outstanding_loan_schedules.length > 0"
                                        color="red"
                                        :content="selectedEntity.outstanding_loan_schedules.length"
                                    >
                                        Overdue Loans
                                    </v-badge>
                                    <span v-else>Overdue Loans</span>
                                </v-tab>

                                <v-tab>Loan Payments Received</v-tab>
                                <!-- <v-tab>Policy Payments Received</v-tab> -->
                                <v-tab>Call Recordings</v-tab>
                                <!-- <v-tab>Lapsable Policies</v-tab> -->
                                
                                <!-- <v-tab>
                                    <v-badge
                                        v-if="selectedEntity.upcoming_policy_payments.length > 0"
                                        color="red"
                                        :content="selectedEntity.upcoming_policy_payments.length"
                                    >
                                        Lapsable Policies
                                    </v-badge>
                                    <span v-else>Lapsable Policies</span>
                                </v-tab> -->
                                
                                <!-- <v-tab>Notes</v-tab> -->
                            </v-tabs>
                        </template>
                    </v-toolbar>
                    <v-progress-linear
                        indeterminate
                        color="primary"
                        class="mb-0"
                        height="10"
                        :active="background_process_running"
                    ></v-progress-linear>

                    <v-tabs-items v-model="loan_details_sub_tab">
                        <!-- Installment Schedule -->
                        <v-tab-item>
                            <br/>
                            <InstallmentScheduleUpcoming
                                v-if="selectedEntity !== null"
                                :collectionObj="selectedEntity"
                            />
                        </v-tab-item>
                        <v-tab-item>
                            <br/>
                            <InstallmentScheduleOverdue
                                v-if="selectedEntity !== null"
                                :collectionObj="selectedEntity"
                            />
                        </v-tab-item>
                        <!-- Transactions History -->
                        <v-tab-item>
                            <br/>
                            <TransactionsHistory
                                v-if="selectedEntity !== null"
                                :entityObj="selectedEntity"
                            />
                        </v-tab-item>
                        <!-- Call Recordings -->
                        <v-tab-item>
                            <br/>
                            <CallRecordings
                                v-if="selectedEntity !== null"
                                :collectionObj="selectedEntity"
                            />
                        </v-tab-item>
                        <!-- Lapsable Policies -->
                        <!-- <v-tab-item>
                            <br/>
                            <LapsablePolicies
                                v-if="selectedEntity !== null"
                                :loanObj="selectedEntity"
                            />
                        </v-tab-item> -->
                    </v-tabs-items>
                </div>
            </v-col>
        </v-row>

        <ViewDebtStatusDialog
            v-if="selectedEntity !== null"
            :dialog="open_view_debt_status_dialog"
            :loan="selectedEntity"
        />
        <LoanPayoutDialog
            v-if="selectedEntity !== null"
            :dialog="open_loan_payout_dialog"
            :loan="selectedEntity"
        />
        <CallDialog 
            v-if="selectedEntity !== null"
            :dialog="show_call_dialog"
            :entityObj="selectedEntity"
            :caller_number="caller_number"
        />

        <v-overlay :value="submitting">
            <v-progress-circular
              color="white"
              indeterminate
              size="150"
              width="9"
            >
              <h3 class="text-center">Loading</h3>
            </v-progress-circular>
        </v-overlay>
    </div>
</template>
<script>
import MemberSearch from "../../member_search";
import AgentSearch from "../../agent_search";
import InstallmentScheduleUpcoming from "./collection_details/upcoming_installment_schedule";
import InstallmentScheduleOverdue from "./collection_details/overdue_installment_schedule";
import TransactionsHistory from "./collection_details/transactions_history";
import CallRecordings from "./collection_details/call_recordings";
// import LapsablePolicies from "./collection_details/lapsable_policies.vue";

// Dialogs
import CallDialog from "../../../dialogs/call_dialog.vue";
import ViewDebtStatusDialog from "../../../dialogs/view_debt_status.vue";
import LoanPayoutDialog from "../../../dialogs/life_cycle/pending_payout/payout_loan.vue";

import { eventBus } from '../../../../../main';
import { store } from "../../../../../../store";
import { emitAlert, emitSuccess } from '../../../../../utils/api';
import converter from "../../../../../mixins/converter";
import { LOAN_STATUSES, BUSINESS_PARTNER_TYPES } from "../../../../../../config";

import { getCollectionCases } from "../../../gql/queries";
import { businessProcessLoan } from "../../../gql/mutations";

export default {
    components: {
        MemberSearch,
        AgentSearch,
        InstallmentScheduleUpcoming,
        InstallmentScheduleOverdue,
        TransactionsHistory,
        CallRecordings,
        // LapsablePolicies,

        // Dialogs
        ViewDebtStatusDialog,
        LoanPayoutDialog,
        CallDialog,
    },
    mixins: [converter],
    props: {},
    data: () => ({
        selectedEntity: null,
        selectedEntityIndex: undefined,
        search_pannel: 0,
        loan_details_sub_tab: null,

        search_fields: {
            loan_number: {
                search: false,
                value: null,
            },
            loan_entity_id_number: {
                search: false,
                value: null,
            },
            agent: {
                search: false,
                value: null,
            },

            // e.g. if = 20, then we return people whose "last_contact_days" >= 20
            days_since_last_contact: {
                search: false,
                value: null,
            },

            // e.g. if = 8, then we return people whom "next_payment_date" <= 8
            days_till_nex_payment: {
                search: false,
                value: null,
            },

            // // This would happen for calls which have been quality assured and marked as ZERO-RESPONSE
            // // The issue here is that we're recording RINGS and RINGS-TILL-VOICEMAIL, etc. So we need them properly marked
            // clients_with_no_response: {
            //     search: false,
            //     value: null,
            // },


            /**
             * CALL TAGGING:
             *  - NO-RESPONSE (CLIENT NOT ANSWERING)
             *  - NO-RESPONSE (VOICEMAIL) - (POTENTIALLY BLOCKED US)
             *  - NO-RESPONSE (NUMBER NOT IN SERVICE)
             *  - PROMISE MADE FOR PAYMENT IN 7/14/21/30 DAYS
             *  - CLIENT IS HOSTILE
             *  - CLIENT HAS NO INCOME SOURCE
             * 
             */ 
        },

        loan_entity: {
            member: null
        },
        loan_agent: {
            agent: null
        },

        effective_date_menu: false,
        submitting: false,

        search_results: [],

        LOAN_STATUSES: LOAN_STATUSES,
        BUSINESS_PARTNER_TYPES: BUSINESS_PARTNER_TYPES,

        open_view_debt_status_dialog: false,
        background_process_running: false,

        show_call_dialog: false,
        caller_number: null,

        // Life cycle management
        open_loan_payout_dialog: false,
    }),
    computed: {
        search_field_selected() {
            return (
                this.search_fields.loan_number.search ||
                this.search_fields.loan_entity_id_number.search ||
                this.search_fields.agent.search ||
                this.search_fields.days_since_last_contact.search ||
                this.search_fields.days_till_nex_payment.search
            )
        },
        isCentralUnderwriterAdminUser() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'admin - central underwriter'
                                                            );
                                                        }).length > 0;

            return result;
        },
        isCollectionsManager() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'collections manager'
                                                            );
                                                        }).length > 0;

            return result;
        },
    },
    methods: {
        callClient() {
            if (this.caller_number === null) {
                const activeCallerPhones = store.state.currentUserAccount.entity.contact_details.filter(cont => {
                    return (
                        cont.type === 'Mobile' &&
                        cont.primary === true &&
                        cont.deleted === false
                    )
                });
                if (activeCallerPhones.length === 0) {
                    emitAlert('You don\'t have an active phone number for handling the call. Please update your phone number details in order to make the call.');
                    return;
                }
    
                if (activeCallerPhones.length > 1) {
                    emitAlert('You have too many phone numbers. Please go to your profile and update your phone number details');
                    return;
                }
    
                this.caller_number = activeCallerPhones[0].details;
            }

            this.show_call_dialog = true;
        },
        getInstallmentCount(item) {
            let installmentCount = 0;
            if (item.upcoming_loan_schedules) {
                installmentCount += item.upcoming_loan_schedules.length;
            }
            if (item.upcoming_policy_payments) {
                installmentCount += item.upcoming_policy_payments.length;
            }
            return installmentCount;
        },
        getTotalOutstanding(item) {
            let totalOutstanding = 0;
            if (item.upcoming_loan_schedules) {
                item.upcoming_loan_schedules.forEach(scheduleItem => {
                    const premium_total_theoretical = scheduleItem.premium_total_theoretical;
                    const premium_total_collected = scheduleItem.premium_total_collected;

                    const diff = premium_total_theoretical - premium_total_collected;
                    totalOutstanding += diff;
                });
            }
            if (item.upcoming_policy_payments) {
                item.upcoming_policy_payments.forEach(policy => {
                    totalOutstanding += policy.premium;
                });
            }
            totalOutstanding = Math.round((totalOutstanding + Number.EPSILON) * 100) / 100;
            return totalOutstanding;
        },
        viewLoanDetails(loanObj, selectedEntityIndex) {
            // console.log(loanObj, selectedEntityIndex);

            if (selectedEntityIndex === undefined) {
                this.selectedEntity = null;
            } else {
                this.selectedEntity = loanObj;
            }
        },
        async submitSearchQuery() {
            this.search_mode = true;
            this.submitting = true;

            const searchObj = {};

            Object.keys(this.search_fields).forEach(searchField => {
                if (this.search_fields[ searchField ].search === true) {
                    if (
                        this.search_fields[ searchField ].value !== null &&
                        this.search_fields[ searchField ].value !== undefined
                    ) {
                        searchObj[ searchField ] = this.search_fields[ searchField ].value;
                    }

                    // Make sure we don't send empty strings
                    if (typeof(this.search_fields[ searchField ].value) === 'string') {
                        if (this.search_fields[ searchField ].value.trim().length === 0) {
                            delete searchObj[ searchField ];
                        }
                    }
                }
            });

            // if (Object.keys(searchObj).length === 0) {
            //     this.submitting = false;
            //     emitAlert('Please capture valid search field values');
            //     return;
            // }

            try {
                const res = await this.$apollo
                                        .query({
                                            query: getCollectionCases,
                                            // fetchPolicy: 'network-only',
                                            fetchPolicy: 'no-cache',
                                            errorPolicy: 'all',
                                            variables: {
                                                objectType: 'COLLECTION_CASE',
                                                pagination: {
                                                    page: 1,
                                                    limit: 1000
                                                },
                                                searchInput: searchObj
                                            },
                                        });
                this.submitting = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }
    
                // this.loans = res.data.getBusinessObject.business_objects;
                // eventBus.$emit('CLOSE_LOAN_SEARCH_DIALOG', res.data.getBusinessObject.business_objects);
                // console.log('RESULTS', res.data.getBusinessObject.business_objects);
                
                // Close the search pannel
                this.search_pannel = 1;
                this.search_results = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        async loadAllActiveCases() {
            this.search_mode = false;
            this.submitting = true;

            // const searchObj = {};

            try {
                const res = await this.$apollo
                                        .query({
                                            query: getCollectionCases,
                                            // fetchPolicy: 'network-only',
                                            fetchPolicy: 'no-cache',
                                            errorPolicy: 'all',
                                            variables: {
                                                objectType: 'COLLECTION_CASE',
                                                pagination: {
                                                    page: 1,
                                                    limit: 1000
                                                },
                                                // searchInput: searchObj
                                            },
                                        });
                this.submitting = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }
    
                // this.loans = res.data.getBusinessObject.business_objects;
                // eventBus.$emit('CLOSE_LOAN_SEARCH_DIALOG', res.data.getBusinessObject.business_objects);
                // console.log('RESULTS', res.data.getBusinessObject.business_objects);
                
                // Close the search pannel
                this.search_pannel = 1;
                this.search_results = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        async clearSearchResults() {
            this.search_mode = false;
            this.selectedEntity = null;
            this.selectedEntityIndex = undefined;
            // this.search_pannel = 0;
            this.loan_details_sub_tab = null;

            this.search_fields = {
                loan_number: {
                    search: false,
                    value: null,
                },
                loan_entity_id_number: {
                    search: false,
                    value: null,
                },
                agent: {
                    search: false,
                    value: null,
                },

                // e.g. if = 20, then we return people whose "last_contact_days" >= 20
                days_since_last_contact: {
                    search: false,
                    value: null,
                },

                // e.g. if = 8, then we return people whom "next_payment_date" <= 8
                days_till_nex_payment: {
                    search: false,
                    value: null,
                },
            };

            this.loan_entity = {
                member: null
            };
            this.loan_agent = {
                agent: null
            };

            this.effective_date_menu = false;
            this.submitting = false;

            this.search_results = [];
            await this.loadAllActiveCases()
        },

        openViewDebitOrderStatus() {
            this.open_view_debt_status_dialog = true;
        },
        closeViewDebtStatus() {
            this.open_view_debt_status_dialog = false;
        },

        async runAllocationProcessForLoan() {
            this.background_process_running = true;

            try {
                const payloadObj = {
                    OP_TYPE: 'RUN_ALLOCATION_PROCESS',
                    _id: this.selectedEntity._id,
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessLoan,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.businessProcessLoan.errors === true) {
                    emitAlert(res.data.businessProcessLoan.message);
                    this.background_process_running = false;
                    return;
                }

                emitSuccess(res.data.businessProcessLoan.message);
                this.background_process_running = false;

                // Update UI model
                this.updateUITableModel(res.data.businessProcessLoan.business_object);
            } catch (error) {
                this.background_process_running = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessLoan.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        },

        markLoanAsPaidOut() {
            this.open_loan_payout_dialog = true;
        },
        closeLoanPayoutDialog(canceled, new_loan) {
            if (canceled === false) {
                // this.selectedEntity = null;

                // // Also update the documents tab
                // this.loan_documents = [];
                // this.amortization_schedule = [];

                // this.refreshLoans();
                // this.unselectSubTabRows();
                this.updateUITableModel(new_loan);
            }

            this.open_loan_payout_dialog = false;
        },

        updateUITableModel(new_loan) {
            if (new_loan !== null) {
                const obj_index = this.search_results.map(obj => { return obj._id; }).indexOf(new_loan._id);
                this.search_results[obj_index] = new_loan;

                this.selectedEntity = new_loan;
                // this.selectedEntityCopy = JSON.parse( JSON.stringify(new_loan) );

                // Deep copy loans for refreshing main data table
                const temp_arr = Array.from(this.search_results);
                this.search_results = temp_arr;
            }
        }
    },
    async mounted() {
        eventBus.$on('CLOSE_CALL_DIALOG', () => { this.show_call_dialog = false; });
        eventBus.$on('CLOSE_VIEW_DEBT_STATUS_DIALOG', this.closeViewDebtStatus);

        // Resolve caller number
        console.log('Resolving caller number');
        const activeCallerPhones = store.state.currentUserAccount.entity.contact_details.filter(cont => {
            return (
                cont.type === 'Mobile' &&
                cont.primary === true &&
                cont.deleted === false
            )
        });

        // console.log('Numbers: ', activeCallerPhones);
        if (activeCallerPhones.length === 1) {
            this.caller_number = activeCallerPhones[0].details;
        }

        await this.loadAllActiveCases()
    },
    watch: {
        "loan_entity.member": function(val) {
            if (val === null) {
                this.search_fields.loan_entity_id_number.value = null;
            } else {
                this.search_fields.loan_entity_id_number.value = val.id_number;
            }
        },
        "loan_agent.agent": function(val) {
            if (val === null) {
                this.search_fields.agent.value = null;
            } else {
                this.search_fields.agent.value = val._id;
            }
            // console.log(val)
        }
    }
}
</script>
<style scoped>
/* Overriding of textbox min height */
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}

.tab-nav .v-tabs {
	display: initial;
	/* padding: initial; */
	margin-left: initial;
	position: initial;
	z-index: initial;
}

a, .v-tab {
    background: #4590a0 !important;
    border-top: initial;
    border-left: initial;
}
</style>